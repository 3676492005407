<section class="intro">
  <img
    class="intro__img"
    src="../../assets/images/bot-hive_maya.jpg"
    alt="profile photo"
  />
  <div class="intro__info">
    <h1 class="intro__info__title intro__info__text">Maya Keeley</h1>
    <p class="intro__info__text intro__info__subtitle">
      Lead Software Engineer
    </p>
    <p class="intro__info__text intro__info__content">
      Lead Full Stack Software Engineer with 5 years of industry experience
      working with startups and non-profits. Keen interest in using technology
      to solve social issues.
    </p>
    <div class="intro__links">
      <a
        class="intro__links__link"
        target="_blank"
        *ngFor="let link of links"
        [href]="link.link"
      >
        <fa-icon [icon]="link.icon"></fa-icon>
      </a>
    </div>
  </div>
</section>
