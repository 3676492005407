<div class="section">
  <div class="section__heading">
    <h2 class="section__title">{{ data.title }}</h2>
  </div>
  <app-projects
    [projects]="data.info"
    *ngIf="data.isProject; else profile"
  ></app-projects>
  <ng-template #profile>
    <app-profile [profile]="data.info"></app-profile>
  </ng-template>
</div>
