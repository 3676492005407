<div class="profile" *ngFor="let item of profile">
  <img class="profile__image" [src]="'assets/images/' + item.image" [alt]="item.companyName" />
  <div class="profile__content">
    <h2 class="profile__title">
      {{ item.companyName }}
    </h2>
    <p class="profile__subtitle">{{ item.jobTitle }}</p>
    <p class="profile__activities" *ngFor="let activity of item.activities">
      {{ activity }}
    </p>
  </div>
</div>
