<div
  class="project"
  *ngFor="let project of projects; let i = index"
  [ngClass]="i % 2 === 0 ? '' : 'project__reverse'"
>
  <img
    class="project__image"
    [src]="project.image.src"
    [alt]="project.image.alt"
  />

  <div class="project__content">
    <div>
      <h2 class="project__title">
        {{ project.jobTitle }}
        <span
          class="project__button project__button--small"
          *ngIf="project.inProgress"
          >In Development</span
        >
      </h2>
      <h2 class="project__italics" *ngIf="project.jobTitle">
        {{ project.name }}
      </h2>
      <p class="project__bio">{{ project.bio }}</p>
    </div>
    <div class="project__tech">
      <h3 class="project__tech__heading">Tech Stack</h3>
      <ul>
        <li *ngFor="let tech of project.techStack">{{ tech }}</li>
      </ul>
    </div>
    <div class="project__buttons">
      <a
        *ngIf="project.websiteUrl"
        class="project__button project__button--primary"
        [href]="'//' + project.websiteUrl"
        target="_blank"
        >View Website</a
      >
      <a
        *ngIf="project.githubUrl"
        [href]="'//' + project.githubUrl"
        target="_blank"
        class="project__button project__button--secondary"
        >View Code</a
      >
    </div>
  </div>
</div>
